import { Observable } from "../../classes/Observable.js";
import { transform } from "../../utils/transform.js";
import { teardown as _teardown } from "../../utils/teardown.js";
export function finalize(teardown) {
  return transform(observable => {
    if (!teardown) return observable;
    return new Observable(obs => {
      var subscription = observable.subscribe(obs);
      return () => {
        subscription.unsubscribe();

        _teardown(teardown)();
      };
    });
  });
}