import { Observable } from "../classes/Observable.js";
import { TypeGuard } from 'type-core';
export function fromEvent(source, name, capture) {
  if (TypeGuard.isEventTarget(source)) {
    return new Observable(obs => {
      function listener(event) {
        obs.next(event);
      }

      try {
        source.addEventListener(name, listener, capture);
      } catch (error) {
        obs.error(error);
      }

      return () => source.removeEventListener(name, listener);
    });
  }

  if (TypeGuard.isEventEmitterLike(source)) {
    return new Observable(obs => {
      function listener() {
        for (var _len = arguments.length, events = new Array(_len), _key = 0; _key < _len; _key++) {
          events[_key] = arguments[_key];
        }

        events.length > 1 ? obs.next(events) : obs.next(events[0]);
      }

      try {
        source.addListener(name, listener);
      } catch (error) {
        obs.error(error);
      }

      return () => source.removeListener(name, listener);
    });
  }

  throw new Error('Source must be an EventEmitter or EventTarget');
}