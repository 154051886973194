export class Accessor {
  static define(obj, key, value) {
    Object.defineProperty(obj, key, {
      enumerable: false,
      writable: true,
      value
    });
  }

  static fallback(obj, key, fn) {
    var response = obj[key];
    if (response !== undefined) return response;
    var value = fn();
    this.define(obj, key, value);
    return value;
  }

}