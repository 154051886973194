import { cache } from "./cache.js";
export default function load(src) {
  var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  // Ensure it's a browser environment
  if (typeof window === 'undefined' || typeof window.document === 'undefined') {
    return Promise.reject(Error("Tried to load a script outside of a browser"));
  }

  if (!Object.hasOwnProperty.call(cache, src)) {
    cache[src] = new Promise((resolve, reject) => {
      var element = document.createElement('script');

      function handleLoad() {
        resolve(null);
        element.removeEventListener('load', handleLoad);
        element.removeEventListener('error', handleError);
      }

      function handleError(e) {
        resolve(e);
        element.removeEventListener('load', handleLoad);
        element.removeEventListener('error', handleError);
      }

      try {
        element.src = src;
        var entries = Object.entries(attributes);
        delete attributes.src;

        for (var [_key, value] of entries) {
          element[_key] = value;
        }

        element.addEventListener('load', handleLoad);
        element.addEventListener('error', handleError);
        document.body.appendChild(element);
      } catch (e) {
        reject(e);
      }
    });
  }

  return cache[src];
}