import { operate } from "../../utils/operate.js";
export function map(projection) {
  return operate(obs => {
    var index = 0;
    return {
      next(value) {
        obs.next(projection(value, index++));
      }

    };
  });
}