function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable no-dupe-class-members */
import { isType } from "../helpers/is-type.js";
import { assert, ensure, coerce, select, collect, take } from "../utils/index.js";
var internal = Symbol('PureCollection');
export class PureCollection {
  constructor(initial) {
    _defineProperty(this, internal, void 0);

    this[internal] = {
      initial: typeof initial === 'function' ? initial : () => initial
    };
  }

  clear() {
    if (Object.hasOwnProperty.call(this[internal], 'value')) {
      delete this[internal].value;
    }

    return this;
  }

  all() {
    if (Object.hasOwnProperty.call(this[internal], 'value')) {
      return this[internal].value;
    }

    var value = this[internal].initial();
    this[internal].value = value;
    return value;
  }

  get(property) {
    return this.all()[property];
  }

  assert(property, deep) {
    return assert(this.get(property), deep);
  }

  take(property, a, b) {
    return take(this.get(property), a, b);
  }

  ensure(property, a, b) {
    return ensure(this.get(property), a, b);
  }

  coerce(property, a, b) {
    return coerce(this.get(property), a, b);
  }

  select(property, a, b, c) {
    return select(this.get(property), a, b, c);
  }

  collect(a, b) {
    return collect(Object.entries(this.all()).reduce((acc, _ref) => {
      var [key, value] = _ref;
      return isType(value) ? Object.assign(acc, {
        [key]: value
      }) : acc;
    }, {}), a, b);
  }

}