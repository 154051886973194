export function isType(value) {
  return isBasicType(value) || typeof value === 'object';
}
export function isBasicType(value) {
  switch (typeof value) {
    case 'undefined':
    case 'string':
    case 'number':
    case 'boolean':
      {
        return true;
      }

    case 'object':
      {
        return value === null;
      }

    default:
      {
        return false;
      }
  }
}