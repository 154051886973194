import { Observable } from "../classes/Observable.js";
import { transform } from "./transform.js";
import { teardown } from "./teardown.js";
import { intercept } from "./intercept.js";
import { TypeGuard } from 'type-core';
export function operate(operation, options) {
  return transform(observable => {
    return new Observable(obs => {
      var response = operation(obs);

      if (!TypeGuard.isArray(response)) {
        return intercept(observable, obs, response, options);
      }

      var subscription = intercept(observable, obs, {
        start: response[0] || undefined,
        next: response[1] || undefined,
        error: response[2] || undefined,
        complete: response[3] || undefined
      }, options);
      return () => {
        subscription.unsubscribe();
        teardown(response[4])();
      };
    });
  });
}