function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { Invoke } from "../helpers/index.js";
import { TypeGuard } from 'type-core';

var _items = new WeakMap();

var _options = new WeakMap();

export class Talkback {
  constructor(item, options) {
    _items.set(this, {
      writable: true,
      value: void 0
    });

    _options.set(this, {
      writable: true,
      value: void 0
    });

    _classPrivateFieldSet(this, _items, TypeGuard.isArray(item) ? item : [item]);

    _classPrivateFieldSet(this, _options, options || {});
  }

  start(subscription) {
    Invoke.observers('start', subscription, _classPrivateFieldGet(this, _items), _classPrivateFieldGet(this, _options));
  }

  next(value) {
    return Invoke.observers('next', value, _classPrivateFieldGet(this, _items), _classPrivateFieldGet(this, _options));
  }

  error(error) {
    return Invoke.observers('error', error, _classPrivateFieldGet(this, _items), _classPrivateFieldGet(this, _options));
  }

  complete() {
    return Invoke.observers('complete', undefined, _classPrivateFieldGet(this, _items), _classPrivateFieldGet(this, _options));
  }

}