import { pipe } from "./pipe.js";
export var create = Object.assign(createFn, {
  async: asyncCreateFn
});

function createFn() {
  return pipe;
}

function asyncCreateFn() {
  return pipe.async;
}