import { shallowEqual } from 'shallow-equal-object';
import deepEqual from 'lodash.isequal';
export function strict(value, query) {
  return value === query;
}
export function partial(value, query) {
  if (value === query) {
    return true;
  }

  if (typeof value !== 'object' || value === null || Array.isArray(value)) {
    return false;
  }

  if (typeof query !== 'object' || query === null || Array.isArray(query)) {
    return false;
  }

  var keys = Object.keys(value);

  for (var key of keys) {
    if (value[key] !== query[key]) return false;
  }

  return true;
}
export function shallow(value, query) {
  return shallowEqual(value, query);
}
export function deep(value, query) {
  return deepEqual(value, query);
}