import { assert } from "../assert/index.js";
import { take } from "../take/index.js";
import { ensure } from "../ensure/index.js";
import { coerce } from "../coerce/index.js";
import { select } from "../select/index.js";
import { CollectError } from "./CollectError.js";
var functions = {
  get() {
    return data => data;
  },

  assert() {
    return data => assert(data);
  },

  take(a, b) {
    return data => take(data, a, b);
  },

  ensure(a, b) {
    return data => ensure(data, a, b);
  },

  coerce(a, b) {
    return data => coerce(data, a, b);
  },

  select(a, b, c) {
    return data => select(data, a, b, c);
  }

};
export function collect(data, a, b) {
  var collector = b || a;
  var options = Object.assign({
    failEarly: false
  }, b ? a : {});
  var response = collector(functions);
  var results = {};
  var errors = {};

  for (var key of Object.keys(response)) {
    var value = response[key];

    try {
      results[key] = typeof value === 'function' ? value(data[key]) : value;
    } catch (err) {
      errors[key] = err;
      if (options.failEarly) break;
    }
  }

  if (Object.keys(errors).length) throw new CollectError(errors);
  return results;
}