export class TypeGuard {
  static isID(item) {
    return TypeGuard.isString(item) || TypeGuard.isNumber(item);
  }

  static isEmpty(item) {
    return TypeGuard.isUndefined(item) || TypeGuard.isNull(item);
  }

  static isFalseLike(item) {
    return !item;
  }

  static isPrimitive(item) {
    return !TypeGuard.isObjectLike(item);
  }

  static isNull(item) {
    return item === null;
  }

  static isUndefined(item) {
    return item === undefined;
  }

  static isBoolean(item) {
    return typeof item === 'boolean';
  }

  static isString(item) {
    return typeof item === 'string';
  }

  static isNumber(item) {
    return typeof item === 'number';
  }

  static isBigInt(item) {
    return typeof item === 'bigint';
  }

  static isSymbol(item) {
    return typeof item === 'symbol';
  }

  static isFunction(item) {
    return typeof item === 'function';
  }

  static isObjectLike(item) {
    return TypeGuard.isObject(item) || TypeGuard.isFunction(item);
  }

  static isObject(item) {
    return typeof item === 'object' && !TypeGuard.isNull(item);
  }

  static isRecordLike(item) {
    return TypeGuard.isRecord(item) || TypeGuard.isFunction(item);
  }

  static isRecord(item) {
    return TypeGuard.isObject(item) && !TypeGuard.isArray(item);
  }

  static isArray(item) {
    return Array.isArray(item);
  }

  static isPromiseLike(item) {
    return Boolean(item) && TypeGuard.isFunction(item.then);
  }

  static isPromise(item) {
    return Boolean(item) && TypeGuard.isFunction(item.then) && TypeGuard.isFunction(item.catch) && TypeGuard.isFunction(item.finally);
  }

  static isIterable(item) {
    return !TypeGuard.isEmpty(item) && TypeGuard.isFunction(item[Symbol.iterator]);
  }

  static isIterator(item) {
    return Boolean(item) && TypeGuard.isFunction(item.next);
  }

  static isEventEmitterLike(item) {
    return Boolean(item) && TypeGuard.isFunction(item.addListener) && TypeGuard.isFunction(item.removeListener);
  }

  static isEventEmitter(item) {
    return TypeGuard.isEventEmitterLike(item) && TypeGuard.isFunction(item.on) && TypeGuard.isFunction(item.once) && TypeGuard.isFunction(item.off) && TypeGuard.isFunction(item.removeAllListeners) && TypeGuard.isFunction(item.setMaxListeners) && TypeGuard.isFunction(item.getMaxListeners) && TypeGuard.isFunction(item.listeners) && TypeGuard.isFunction(item.rawListeners) && TypeGuard.isFunction(item.emit) && TypeGuard.isFunction(item.listenerCount) && TypeGuard.isFunction(item.prependListener) && TypeGuard.isFunction(item.prependOnceListener) && TypeGuard.isFunction(item.eventNames);
  }

  static isEventTarget(item) {
    return Boolean(item) && TypeGuard.isFunction(item.addEventListener) && TypeGuard.isFunction(item.removeEventListener) && TypeGuard.isFunction(item.dispatchEvent);
  }

}