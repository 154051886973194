import { SubscriptionManager } from "./SubscriptionManager.js";
import { TypeGuard } from 'type-core';
var $empty = Symbol('empty');
export class Invoke {
  static method(obj, key, payload, onEmpty) {
    if (!obj) return;
    var method = $empty;

    try {
      method = obj[key];
      payload ? method.call(obj, ...payload) : method.call(obj);
    } catch (err) {
      if (TypeGuard.isEmpty(method)) onEmpty && onEmpty();else throw err;
    }
  }

  static observer(action, payload, subscription, hooks) {
    if (SubscriptionManager.isClosed(subscription)) {
      if (action === 'error') hooks.onUnhandledError(payload, subscription);
      return;
    }

    var observer = SubscriptionManager.getObserver(subscription);
    if (action !== 'start') SubscriptionManager.close(subscription);

    try {
      this.method(observer, action, action === 'complete' ? null : [payload], action === 'error' ? () => hooks.onUnhandledError(payload, subscription) : null);
    } catch (err) {
      hooks.onUnhandledError(err, subscription);
    } finally {
      if (action !== 'start') {
        try {
          subscription.unsubscribe();
        } catch (err) {
          hooks.onUnhandledError(err, subscription);
        }
      }
    }
  }

  static observers(action, payload, items, options) {
    for (var item of items) {
      var method = $empty;

      try {
        method = item[action];
        method.call(item, payload);
      } catch (err) {
        if (TypeGuard.isEmpty(method)) continue;else if (options.onError) options.onError(err);
      }

      if (!options.multicast) break;
    }
  }

  static subscriptionObservers(action, payload, items) {
    for (var item of items) {
      item[action](payload);
    }
  }

}