import { Observable } from "../classes/Observable.js";
import { map } from "../operators/map/index.js";
import { intercept } from "../utils/intercept.js";
import { from } from "./from.js";
import { merge } from "./merge.js";
import { into } from 'pipettes';
export function combine(observables) {
  if (!observables) return combineList();

  if (Array.isArray(observables)) {
    return into(combineList(observables), map(current => Array.from(current)));
  }

  var record = observables;
  var dict = {};
  var list = [];

  for (var [key, obs] of Object.entries(record)) {
    dict[list.length] = key;
    list.push(obs);
  }

  return into(combineList(list), map(current => {
    return current.reduce((acc, value, i) => {
      acc[dict[i]] = value;
      return acc;
    }, {});
  }));
}
/** @ignore */

function combineList(arr) {
  if (!arr || arr.length < 1) {
    throw Error("Must provide at least one observable to combine");
  }

  var observables = arr.map(from);

  if (observables.length === 1) {
    return into(observables[0], map(value => [value]));
  }

  var sources = observables.map((obs, i) => {
    return into(from(obs), map(value => [i, value]));
  });
  return new Observable(obs => {
    var pending = new Set(Array(observables.length).fill(0).map((_, i) => i));
    var current = Array(observables.length).fill(0);
    return intercept(merge(...sources), obs, {
      next(_ref) {
        var [index, value] = _ref;
        current[index] = value;
        if (pending.has(index)) pending.delete(index);
        if (!pending.size) obs.next(current);
      }

    });
  });
}