function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { Handler, Resolver } from "../../helpers/index.js";
import { Validate } from "./helpers.js";
import { TypeGuard } from 'type-core';

var _closed = new WeakMap();

var _iterator = new WeakMap();

export class PullableIterator {
  constructor(iterator) {
    _closed.set(this, {
      writable: true,
      value: void 0
    });

    _iterator.set(this, {
      writable: true,
      value: void 0
    });

    Validate.counter(iterator);

    _classPrivateFieldSet(this, _closed, false);

    _classPrivateFieldSet(this, _iterator, iterator);
  }

  next(value) {
    if (_classPrivateFieldGet(this, _closed)) return {
      complete: true
    };

    var iterator = _classPrivateFieldGet(this, _iterator);

    var method = Handler.noop;
    return Resolver.resolve(() => (method = iterator.next).call(iterator, value), result => {
      if (TypeGuard.isObject(result)) return result;
      throw new TypeError('Expected result to be an object');
    }, err => {
      if (TypeGuard.isEmpty(method)) return {
        complete: true
      };
      throw err;
    });
  }

  error(error) {
    if (_classPrivateFieldGet(this, _closed)) return {
      complete: true
    };

    var iterator = _classPrivateFieldGet(this, _iterator);

    var method = Handler.noop;
    return Resolver.resolve(() => (method = iterator.error).call(iterator, error), result => {
      if (TypeGuard.isObject(result)) return result;

      _classPrivateFieldSet(this, _closed, true);

      throw new TypeError('Expected result to be an object');
    }, err => {
      _classPrivateFieldSet(this, _closed, true);

      if (TypeGuard.isEmpty(method)) throw error;else throw err;
    });
  }

  complete() {
    if (_classPrivateFieldGet(this, _closed)) return;

    var iterator = _classPrivateFieldGet(this, _iterator);

    _classPrivateFieldSet(this, _closed, true);

    var method = Handler.noop;
    return Resolver.resolve(() => (method = iterator.complete).call(iterator), null, err => {
      if (!TypeGuard.isEmpty(method)) throw err;
    });
  }

}