function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { Accessor } from "../../../helpers/index.js";
import { SubscriptionManager, Invoke } from "../helpers/index.js";
import { Hooks } from "./Hooks.js";
import { TypeGuard } from 'type-core';
/** @ignore */

var $empty = Symbol('empty');
/** @ignore */

var $subscription = Symbol('subscription');

var _hooks = new WeakMap();

class SubscriptionObserver {
  constructor(subscription) {
    _hooks.set(this, {
      writable: true,
      value: void 0
    });

    _defineProperty(this, $subscription, void 0);

    _classPrivateFieldSet(this, _hooks, Hooks.from(arguments.length <= 1 ? undefined : arguments[1]));

    Accessor.define(this, $subscription, subscription);
  }

  get closed() {
    return SubscriptionManager.isClosed(this[$subscription]);
  }

  next(value) {
    var subscription = this[$subscription];

    if (SubscriptionManager.isClosed(subscription)) {
      _classPrivateFieldGet(this, _hooks).onStoppedNotification(value, subscription);

      return;
    } // Does not use invoke to improve performance


    var observer = SubscriptionManager.getObserver(subscription);
    var method = $empty;

    try {
      (method = observer.next).call(observer, value);
    } catch (err) {
      if (!TypeGuard.isEmpty(method)) {
        _classPrivateFieldGet(this, _hooks).onUnhandledError(err, subscription);
      }
    }
  }

  error(error) {
    Invoke.observer('error', error, this[$subscription], _classPrivateFieldGet(this, _hooks));
  }

  complete() {
    Invoke.observer('complete', undefined, this[$subscription], _classPrivateFieldGet(this, _hooks));
  }

}

SubscriptionObserver.prototype.constructor = Object;
export { SubscriptionObserver };