var _Symbol$asyncIterator;

function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { TypeGuard } from 'type-core';
import { isPullableCompatible, isPullableLike } from "../utils/index.js";
import { Consume, From, Validate } from "./helpers.js";
import { PullableIterator } from "./PullableIterator.js";

var _source = new WeakMap();

_Symbol$asyncIterator = Symbol.asyncIterator;
export class Pullable {
  static from(item) {
    if (item instanceof Pullable) return item;

    if (isPullableLike(item)) {
      return new Pullable(item.source);
    }

    if (isPullableCompatible(item)) {
      return From.asyncIteratorToPullable(this, () => item[Symbol.asyncIterator]());
    }

    if (TypeGuard.isIterable(item)) {
      return From.iteratorToPullable(this, () => item[Symbol.iterator]());
    }

    throw new TypeError("Unable to convert ".concat(typeof item, " into a Pullable"));
  }

  constructor(provider) {
    _source.set(this, {
      writable: true,
      value: void 0
    });

    Validate.provider(provider);

    _classPrivateFieldSet(this, _source, () => new PullableIterator(provider()));
  }

  [_Symbol$asyncIterator]() {
    return From.pullableToAsyncIterator(this);
  }

  get source() {
    return _classPrivateFieldGet(this, _source);
  }

  consume(consumer) {
    Validate.consumer(consumer);
    var sink = new PullableIterator(consumer());
    var source = this.source();
    Consume.process(source, sink, undefined);
  }

}