function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Promist } from "./Promist.js";
var INTERNAL_SYMBOL = Symbol('internal');

/**
 * `LazyPromist`s don't run their constructor `executor` until
 * after they've been explicitly expected to resolve
 * by a `then`, `catch`, or `finally` call.
 */
export class LazyPromist extends Promist {
  constructor(executor) {
    super();

    _defineProperty(this, INTERNAL_SYMBOL, void 0);

    var executed = false;
    this[INTERNAL_SYMBOL] = {
      get executed() {
        return executed;
      },

      execute: () => {
        if (executed || this.status !== 'pending') return;
        executed = true;
        var complete = executor(this.resolve.bind(this), this.reject.bind(this));
        var {
          onstart
        } = this[INTERNAL_SYMBOL];
        if (onstart) onstart();

        if (complete && typeof complete === 'function') {
          if (this.status === 'pending') this.react.then(complete);else complete();
        }
      }
    };
  }

  then(onfulfilled, onrejected) {
    this[INTERNAL_SYMBOL].execute();
    return super.then(onfulfilled, onrejected);
  }

  timeout(ms, reason) {
    var {
      executed,
      onstart
    } = this[INTERNAL_SYMBOL];

    if (executed) {
      super.timeout(ms, reason);
    } else {
      this[INTERNAL_SYMBOL].onstart = onstart ? () => {
        onstart();
        super.timeout(ms, reason);
      } : () => super.timeout(ms, reason);
    }
  }

  fallback(ms, value) {
    var {
      executed,
      onstart
    } = this[INTERNAL_SYMBOL];

    if (executed) {
      super.fallback(ms, value);
    } else {
      this[INTERNAL_SYMBOL].onstart = onstart ? () => {
        onstart();
        super.fallback(ms, value);
      } : () => super.fallback(ms, value);
    }
  }

}