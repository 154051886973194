import { getSchema } from "./get-schema.js";
export function getPositionalAssertSchema(a, b) {
  if (typeof a === 'boolean') return {
    assert: a,
    schema: getSchema(b)
  };
  if (!a) return {
    assert: false,
    schema: getSchema(b)
  };
  return {
    assert: false,
    schema: getSchema(a)
  };
}