import parseTheme from "./parse/theme.js";

/**
 * Generates styles as an object -`styles`- and as a string -`css`- assigning css variables to their defined values. You can then inject these for usage in your css.
 *
 * If a second object is passed, it will also generate `setup` styles, assigning property values for all elements with names of `elements` keys to their respective variable.
 */
export function theming(theme, elements) {
  var base = {
    css: '',
    styles: {}
  };
  var setup = {
    css: '',
    styles: {}
  };

  if (theme) {
    parseTheme(theme, (variable, value) => {
      base.css += "\n".concat(variable, ": ").concat(value, ";");
      base.styles[variable] = value;
    });
  }

  if (elements) {
    parseTheme(elements, (variable, value) => {
      base.css += "\n".concat(variable, ": ").concat(value, ";");
      base.styles[variable] = value;
      var [name, ...arr] = variable.slice(2).split('-');
      var property = arr.join('-');
      if (!setup.styles[name]) setup.styles[name] = {};
      setup.styles[name][property] = "var(".concat(variable, ");");
      setup.css += "\n".concat(name, " { ").concat(property, ": var(").concat(variable, "); }");
    });
  }

  base.css = base.css.trim();
  setup.css = setup.css.trim();
  return elements ? Object.assign(base, {
    setup
  }) : base;
}