import flatten from 'flat';
import decamelize from 'decamelize';
import parseValue from "./value.js";
export default function parseTheme(theme, fn) {
  var flat = flatten(theme, {
    delimiter: '-'
  });
  var entries = Object.entries(flat);

  for (var [camelName, rawValue] of entries) {
    var name = decamelize(camelName, '-');

    var _value = parseValue(rawValue);

    if (_value) fn('--' + name, _value);
  }
}