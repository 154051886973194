function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { Observable, Subject } from 'multitude/push';

var _enable = new WeakMap();

var _disable = new WeakMap();

var _subject = new WeakMap();

var _observable = new WeakMap();

/**
 * The most basic Machine abstract class.
 * The `active` and `active$` properties,
 * as well as the `enable` and `disable` methods,
 * are set as `protected` instead of `public`.
 * For cases when these are only to be used internally.
 */
export class MachineEnclosure {
  constructor(deps, enable) {
    _enable.set(this, {
      writable: true,
      value: void 0
    });

    _disable.set(this, {
      writable: true,
      value: void 0
    });

    _subject.set(this, {
      writable: true,
      value: void 0
    });

    _observable.set(this, {
      writable: true,
      value: void 0
    });

    _defineProperty(this, "deps", void 0);

    this.deps = deps;

    _classPrivateFieldSet(this, _enable, enable);

    _classPrivateFieldSet(this, _disable, null);

    _classPrivateFieldSet(this, _subject, Subject.of(false, {
      replay: true
    }));

    _classPrivateFieldSet(this, _observable, Observable.from(_classPrivateFieldGet(this, _subject)));
  }

  get active() {
    return _classPrivateFieldGet(this, _subject).value;
  }

  get active$() {
    return _classPrivateFieldGet(this, _observable);
  }
  /**
   * Sets `active` to `true` and runs the `enable` function
   * passed as a constructor argument.
   */


  enable() {
    if (this.active) return;

    var fn = _classPrivateFieldGet(this, _enable);

    if (fn) _classPrivateFieldSet(this, _disable, fn());

    _classPrivateFieldGet(this, _subject).next(true);
  }
  /**
   * Sets `active` to `false`, runs the `disable` function
   * passed as a constructor argument, if any, and unsubscribes
   * from all subscriptions returned by `enable`, if any.
   */


  disable() {
    if (!this.active) return;

    var disable = _classPrivateFieldGet(this, _disable);

    if (typeof disable === 'function') {
      disable();
    } else if (Array.isArray(disable)) {
      disable.map(subscription => subscription.unsubscribe());
    } else if (disable) {
      disable.unsubscribe();
    }

    _classPrivateFieldSet(this, _disable, null);

    _classPrivateFieldGet(this, _subject).next(false);
  }

}
/**
 * A `Machine` implementation as an abstract class.
 */

export class SuperMachine extends MachineEnclosure {
  get active() {
    return super.active;
  }

  get active$() {
    return super.active$;
  }

  enable() {
    super.enable();
  }

  disable() {
    super.disable();
  }

}
/**
 * A `Machine` implementation as a concrete class.
 */

export class MachineSubject extends SuperMachine {
  constructor(enable) {
    super(null, enable);
  }

}