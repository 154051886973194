import { operate } from "../../utils/operate.js";
export function distinct(selector) {
  return operate(obs => {
    var index = 0;
    var values = new Set();
    return [null, function next(value) {
      var selectedValue = selector ? selector(value, index++) : value;

      if (!values.has(selectedValue)) {
        values.add(selectedValue);
        obs.next(value);
      }
    }, null, null, function teardown() {
      values.clear();
    }];
  });
}