import { into } from 'pipettes';
import { assert } from "../assert/index.js";
export function take(data, a, b) {
  var args = {
    assert: b ? a : false,
    strategy: b || a
  };
  return into(() => {
    if (!Array.isArray(data)) return data;

    switch (args.strategy) {
      case 'one':
        {
          if (data.length > 1) {
            throw Error("Expected data to contain no more than one value");
          }

          return data[0];
        }

      case 'first':
        {
          return data[0];
        }

      case 'maybe':
        {
          for (var value of data) {
            if (value !== undefined) return value;
          }

          return undefined;
        }

      default:
        {
          throw Error("Invalid take strategy: ".concat(args.strategy));
        }
    }
  }, fn => {
    var value = fn();
    return args.assert ? assert(value) : value;
  });
}