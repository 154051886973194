import { Observable } from "../../classes/Observable.js";
import { transform } from "../../utils/transform.js";
import { merge } from "../../creators/merge.js";
export function start(value, strategy) {
  var always = strategy !== 'no-emit';
  return transform(observable => {
    return always ? merge(Observable.of(value), observable) : new Observable(obs => {
      var didEmit = false;
      var subscription = observable.subscribe({
        next(value) {
          didEmit = true;
          obs.next(value);
        },

        error(err) {
          didEmit = true;
          obs.next(value);
          obs.error(err);
        },

        complete() {
          didEmit = true;
          obs.next(value);
          obs.complete();
        }

      });
      if (!didEmit) obs.next(value);
      return () => subscription;
    });
  });
}