import { operate } from "../../utils/operate.js";
import { intercept } from "../../utils/intercept.js";
export function mergeMap(projection) {
  return operate(obs => {
    var index = 0;
    var parentComplete = false;
    var completeSubscriptions = 0;
    var subscriptions = [];

    function unsubscribe() {
      for (var subscription of subscriptions) {
        subscription.unsubscribe();
      }
    }

    return [null, function next(value) {
      if (obs.closed) return;
      intercept(projection(value, index++), obs, {
        start(subscription) {
          subscriptions.push(subscription);
        },

        error(error) {
          obs.error(error);
          unsubscribe();
        },

        complete() {
          completeSubscriptions++;
          if (!parentComplete) return;

          if (completeSubscriptions >= subscriptions.length) {
            obs.complete();
            unsubscribe();
          }
        }

      });
    }, null, function complete() {
      parentComplete = true;

      if (completeSubscriptions >= subscriptions.length) {
        obs.complete();
      }
    }, function teardown() {
      if (obs.closed || !parentComplete) unsubscribe();
    }];
  });
}