/**
 * A normalized `Error`. See `normalize`.
 */

/**
 * Normalizes an error -it assumes an instance of `Error` is passed.
 * Guarantees the error will have a `name`, `message`, and `stack` properties.
 */
export function normalize(error, options) {
  var opts = Object.assign({
    message: 'An error occurred',
    name: 'Error'
  }, options);
  if (!error.name) error.name = opts.name;
  if (!error.message) error.message = opts.message;
  if (!error.stack) error.stack = "Error: ".concat(error.message);
  return error;
}