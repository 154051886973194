import { TypeGuard } from 'type-core';
import { normalize } from "./normalize.js";
import { capture } from "./capture.js";
import { Exception } from "../Exception.js";

/**
 * Will return `error` if an instance of `Error` is passed;
 * otherwise, when lacking a `create` function,
 * it will instantiate an `Exception` and return it.
 * When a new `Exception` is created, `Exception.data`
 * will be populated with `error`.
 * 
 * @param error a target object
 * @param create an optional error returning function to run when `error` is not an instance of `Error`
 * @param options 
 */
export function ensure(error, create, options) {
  return trunk(error, create || null, Object.assign({
    normalize: true,
    capture: false,
    Error: Error
  }, options));
}

function trunk(error, create, options) {
  var isError = error instanceof options.Error;

  if (!isError) {
    if (TypeGuard.isFunction(create)) {
      error = create(error);
    } else {
      var message = stringify(TypeGuard.isObject(error) && Object.hasOwnProperty.call(error, 'message') ? error.message : error);
      error = new Exception(message, null, error);
    }

    if (error && options.capture) capture(error);
  }

  return options.normalize ? normalize(error, TypeGuard.isBoolean(options.normalize) ? null : options.normalize) : error;
}

function stringify(message) {
  try {
    if (message === undefined) return '';
    if (TypeGuard.isPrimitive(message)) return String(message);
    if (TypeGuard.isFunction(message)) return "function ".concat(message.name).trim();
    return JSON.stringify(message);
  } catch (_) {
    return '';
  }
}