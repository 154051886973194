import { TypeGuard } from 'type-core';
export class From {
  static compatible(Constructor, compatible) {
    var observable = compatible[Symbol.observable]();

    if (!TypeGuard.isObject(observable) && !TypeGuard.isFunction(observable)) {
      throw new TypeError('Invalid Observable compatible object');
    }

    return this.like(Constructor, observable);
  }

  static like(Constructor, like) {
    return like.constructor === Constructor ? like : new Constructor(observer => like.subscribe(observer));
  }

  static iterable(Constructor, iterable) {
    return new Constructor(obs => {
      for (var item of iterable) {
        obs.next(item);
      }

      obs.complete();
      return () => undefined;
    });
  }

}