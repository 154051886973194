/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { readableColor, lighten, darken, parseToRgb } from 'polished';

/**
 * Returns a function that, taking a record of `PaletteInput`s,
 * returns a record of complete `Palettes`.
 */
export function palettes(options) {
  var opts = Object.assign({
    contrastLight: '#fafafa',
    contrastDark: '#2e2e2e',
    tintBy: 0.2,
    shadeBy: 0.1
  }, options);
  return function create(items) {
    var entries = Object.entries(items);
    var response = {};

    for (var [name, palette] of entries) {
      var main = palette.main;
      var contrast = palette.contrast || readableColor(main, opts.contrastLight, opts.contrastDark, false);
      var mainRgb = parseToRgb(main);
      var contrastRgb = parseToRgb(contrast);
      response[name] = {
        main,
        contrast,
        tint: palette.tint || lighten(opts.tintBy, main),
        shade: palette.shade || darken(opts.shadeBy, main),
        accent: palette.accent || readableColor(main, darken(opts.shadeBy, contrast), lighten(opts.tintBy, contrast)),
        mainRgb: "".concat(mainRgb.red, ", ").concat(mainRgb.green, ", ").concat(mainRgb.blue),
        contrastRgb: "".concat(contrastRgb.red, ", ").concat(contrastRgb.green, ", ").concat(contrastRgb.blue)
      };
    }

    return response;
  };
}
/**
 * Creates a `Material UI` compatible palette from a `Palette`
 */

palettes.mui = function muiPalettes(palettes) {
  var toRgb = color => Object.hasOwnProperty.call(color, 'alpha') ? "rgba(".concat(color.red, ", ").concat(color.green, ", ").concat(color.blue, ", ").concat(color.alpha, ")") : "rgb(".concat(color.red, ", ").concat(color.green, ", ").concat(color.blue, ")");

  var entries = Object.entries(palettes);
  var response = {};

  for (var [key, value] of entries) {
    response[key] = {
      main: toRgb(parseToRgb(value.main)),
      light: toRgb(parseToRgb(value.tint)),
      dark: toRgb(parseToRgb(value.shade)),
      contrastText: toRgb(parseToRgb(value.contrast))
    };
  }

  return response;
};