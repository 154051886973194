import { Observable } from "../classes/Observable.js";
import { TypeGuard } from 'type-core';
export function interval(every) {
  var options = !every || TypeGuard.isNumber(every) ? {
    every
  } : every;
  var cancel = options.cancel;
  var promise = TypeGuard.isPromiseLike(cancel) ? cancel : null;
  var callback = TypeGuard.isFunction(cancel) ? cancel : null;
  return new Observable(obs => {
    var index = -1;
    var interval = setInterval(() => {
      index++;
      obs.next(index);
      if (!callback) return;

      try {
        if (callback(index)) {
          obs.complete();
          clearInterval(interval);
        }
      } catch (err) {
        obs.error(err);
      }
    }, options.every || 0);

    if (promise) {
      promise.then(() => {
        clearInterval(interval);
        obs.complete();
      }, err => {
        clearInterval(interval);
        obs.error(err);
      });
    }

    return () => clearInterval(interval);
  });
}