function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { TypeGuard } from 'type-core';
import { Observable, Subject, changes, share } from 'multitude/push';
import { into } from 'pipettes';
/**
 * The most basic Source abstract class.
 * The `state` and `state$` properties,
 * as well as the `next` method,
 * are set as `protected` instead of `public`.
 * For cases when these are only to be used internally.
 */

var _projection = new WeakMap();

var _subject = new WeakMap();

var _observable = new WeakMap();

export class SourceEnclosure {
  constructor(state, deps, projection) {
    _projection.set(this, {
      writable: true,
      value: void 0
    });

    _subject.set(this, {
      writable: true,
      value: void 0
    });

    _observable.set(this, {
      writable: true,
      value: void 0
    });

    _defineProperty(this, "deps", void 0);

    _defineProperty(this, "source", void 0);

    var value = TypeGuard.isRecord(state) ? _objectSpread({}, state) : state;

    var project = projection || (value => value);

    this.deps = deps;
    this.source = value;

    _classPrivateFieldSet(this, _projection, project);

    _classPrivateFieldSet(this, _subject, Subject.of(project(value), {
      replay: true
    }));

    _classPrivateFieldSet(this, _observable, into(_classPrivateFieldGet(this, _subject), changes('shallow'), share({
      policy: 'on-demand',
      replay: true
    }), Observable.from));
  }
  /**
   * Current instance state.
   */


  get state() {
    return _classPrivateFieldGet(this, _subject).value;
  }
  /**
   * An multicast *Observable* that emits the new `state`
   * when updated through the `next` method.
   */


  get state$() {
    return _classPrivateFieldGet(this, _observable);
  }
  /**
   * Updates the instance `state`.
   * If it is an *object,* it will create a new state *object*
   * by merging `state` with the current instance state.
   */


  next(state) {
    var source = this.source;

    var subject = _classPrivateFieldGet(this, _subject);

    var next = TypeGuard.isRecord(state) ? _objectSpread(_objectSpread({}, source), state) : state;
    this.source = next;
    subject.next(_classPrivateFieldGet(this, _projection).call(this, next));
  }

}
/**
 * A `Source` implementation as an abstract class. See `SourceEnclosure`.
 */

export class SuperSource extends SourceEnclosure {
  get state() {
    return super.state;
  }

  get state$() {
    return super.state$;
  }

}
/**
 * A `Source` whose `state` can be externally updated.
 */

export class SourceSubject extends SuperSource {
  constructor(state, projection) {
    super(state, null, projection);
  }

  next(state) {
    return super.next(state);
  }

}