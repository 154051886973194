/**
 * Calls `Machine.enable` before render and `Machine.disable` on unmount.
 */
export function useMachine(React, create) {
  var machine = React.useMemo(() => {
    var instance = create();
    instance.enable();
    return instance;
  }, []);
  React.useEffect(() => () => machine.disable(), []);
  return machine;
}