function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

import { TypeGuard } from 'type-core';
export class Create {
  /**
   * Creates a successful *result* with `data`.
   */
  static success(data) {
    return {
      success: true,
      data
    };
  }
  /**
   * Creates a failed *result* with `data`.
   */


  static failure(data) {
    return {
      success: false,
      data
    };
  }
  /**
   * Creates a *result* that will be successful and contain the
   * return value of `fn` as `data`, or otherwise be failed if
   * `fn` throws.
   */


  static execute(fn) {
    try {
      var value = fn();
      return Create.success(value);
    } catch (err) {
      return Create.failure(err);
    }
  }
  /**
   * Returns a *Promise* of a *result,* that will be successful if `promise`
   * resolves, and failed otherwise.
   * @param promise a promise or a promise returning function
   */


  static promise(promise) {
    return _asyncToGenerator(function* () {
      try {
        var value = yield TypeGuard.isFunction(promise) ? promise() : promise;
        return Create.success(value);
      } catch (err) {
        return Create.failure(err);
      }
    })();
  }
  /**
   * Returns an *Observable* of *result,* that will be successful
   * as long as the original `observable` doesn't error, and failed otherwise.
   * @param Constructor an ES Observable constructor
   * @param observable an ES Observable
   * @param completeOnFail whether the resulting observable should complete after a failed *result*
   */


  static observable(Constructor, observable, completeOnFail) {
    return new Constructor(obs => {
      var subscription = observable.subscribe({
        next(value) {
          obs.next(Create.success(value));
        },

        error(error) {
          obs.next(Create.failure(error));
          if (completeOnFail) return obs.complete();
        },

        complete() {
          obs.complete();
        }

      });
      return () => subscription.unsubscribe();
    });
  }

  /**
   * Combines multiple results. Takes either a record or an
   * array of *result.*
   * Returns a *result* that will be failed if any of the
   * input results fail, otherwise return `null` if any of the
   * inputs are `null`, or succeed with `data` of a
   * record or array of the result's `data` fields.
   */
  static combine(results) {
    if (TypeGuard.isArray(results)) {
      var data = [];
      var isNull = false;

      for (var result of results) {
        if (!result) {
          isNull = true;
        } else {
          if (!result.success) return Create.failure(result.data);
          data.push(result.data);
        }
      }

      return isNull ? null : Create.success(data);
    } else {
      var _data = {};
      var _isNull = false;

      for (var [key, _result] of Object.entries(results)) {
        if (!_result) {
          _isNull = true;
        } else {
          if (!_result.success) return Create.failure(_result.data);
          _data[key] = _result.data;
        }
      }

      return _isNull ? null : Create.success(_data);
    }
  }

}