import { Create } from "./Create.js";
export class Operate {
  /**
   * For an input of a *result* or `null`,
   * the returning function returns the *result* if not `null`,
   * or a successful *result* of `value` if `null`.
   */
  static fallback(value) {
    return result => {
      return result || Create.success(value);
    };
  }
  /**
   * Maps a *result* to their `data`, with optional
   * remaps for `data` upon a successful and/or failed *result.*
   */


  static transform(onSuccess, onFailure) {
    return result => {
      if (!result) return result;

      if (result.success) {
        return onSuccess ? onSuccess(result.data) : result.data;
      } else {
        return onFailure ? onFailure(result.data) : result.data;
      }
    };
  }
  /**
   * Maps a *result* to a *result* with different `data` values.
   */


  static map(onSuccess, onFailure) {
    return result => {
      if (!result) return result;

      if (result.success) {
        return onSuccess ? Create.success(onSuccess(result.data)) : result;
      } else {
        return onFailure ? Create.failure(onFailure(result.data)) : result;
      }
    };
  }
  /**
   * Allows to optionally convert an input successful *result* into a
   * failed *Result* with the same `data`, and vice-versa.
   * @param onSuccess whether to flip a successful *result* to a failed *result*
   * @param onFailure whether to flip a failed *result* to a successful *result*
   */


  static flip(onSuccess, onFailure) {
    return result => {
      if (!result) return result;

      if (result.success) {
        return onSuccess ? Create.failure(result.data) : result;
      } else {
        return onFailure ? Create.success(result.data) : result;
      }
    };
  }
  /**
   * Allows for the execution of side effects upon
   * a successful or failed *result.*
   */


  static tap(onSuccess, onFailure) {
    return result => {
      if (result) {
        if (result.success) {
          if (onSuccess) onSuccess(result.data);
        } else {
          if (onFailure) onFailure(result.data);
        }
      }

      return result;
    };
  }

}