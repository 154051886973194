function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Accessor } from "../../helpers/index.js";
import { from } from "../creators/from.js";
import { tap } from "../operators/tap/index.js";
import { Multicast } from "./Multicast.js";
import { into } from 'pipettes';
/** @ignore */

var $observer = Symbol('observer');
export class Subject extends Multicast {
  static of(item, options) {
    var subject = new this(options);
    subject.next(item);
    return subject;
  }

  static from(item, options) {
    if (item.constructor === this) return item;
    var observable = from(item);
    var subject = new this(options);
    var subscription;
    into(observable, tap({
      start: subs => subscription = subs
    })).subscribe(subject);
    subject.subscribe({
      error: subscription.unsubscribe.bind(subscription),
      complete: subscription.unsubscribe.bind(subscription)
    });
    return subject;
  }

  constructor(options) {
    var observer;
    super(obs => {
      observer = obs;
    }, options, {
      onCreate: connect => connect()
    });

    _defineProperty(this, $observer, void 0);

    Accessor.define(this, $observer, observer);
  }

  next(value) {
    return this[$observer].next(value);
  }

  error(error) {
    return this[$observer].error(error);
  }

  complete() {
    return this[$observer].complete();
  }

}