import { useSource } from "./use-source.js";
import { useMachine } from "./use-machine.js";
/**
 * Shorthand for calling `useMachine` and `useSource` on a *Resource.*
 */

export function useResource(React, create) {
  var instance = useMachine(React, create);
  useSource(React, () => instance);
  return instance;
}