export function assert(data, deep) {
  if (data === undefined) throw Error("Expected data not to be undefined");

  if (deep && typeof data === 'object' && data !== null) {
    var items = Array.isArray(data) ? data : Object.values(data);

    for (var item of items) {
      if (item === undefined) {
        throw Error("An inner value of data is not defined");
      }
    }
  }

  return data;
}