function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export class CollectError extends Error {
  constructor(errors) {
    var message = 'The following errors where found:';

    for (var [key, error] of Object.entries(errors)) {
      message += "\n\t".concat(key, ": ").concat(error.message.replace('\n', '\n\t\t'));
    }

    super(message);

    _defineProperty(this, "errors", void 0);

    this.errors = errors;
  }

}