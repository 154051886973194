import { operate } from "../../utils/operate.js";
import { TypeGuard } from 'type-core';
/**
 * @param every default: 2
 */

export function group(every) {
  var number = TypeGuard.isEmpty(every) ? 2 : every;
  return operate(obs => {
    var arr = [];
    return {
      next(value) {
        arr.push(value);

        if (arr.length >= number) {
          var response = arr;
          arr = [];
          obs.next(response);
        }
      }

    };
  });
}