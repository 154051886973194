var _Symbol$observable;

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

/* eslint-disable @typescript-eslint/no-use-before-define */
import { HooksManager } from "../helpers/index.js";
import { from } from "../creators/from.js";
import { Invoke } from "./helpers/index.js";
import { Observable } from "./Observable.js";
import { TypeGuard } from 'type-core';

var _value = new WeakMap();

var _termination = new WeakMap();

_Symbol$observable = Symbol.observable;
export class Multicast extends Observable {
  static of(item) {
    var options = arguments.length <= 1 ? undefined : arguments[1];
    var hooks = arguments.length <= 2 ? undefined : arguments[2];
    return new this(obs => {
      obs.next(item);
      obs.complete();
    }, hooks, options);
  }

  static from(item, options, hooks) {
    if (item.constructor === this) return item;
    var observable = from(item);
    return new this(obs => observable.subscribe(obs), options, hooks);
  }

  constructor(subscriber, options, hooks) {
    super(obs => {
      var termination = _classPrivateFieldGet(this, _termination);

      if (termination) {
        return typeof termination === 'boolean' ? obs.complete() : obs.error(termination[0]);
      }

      for (var value of values) {
        obs.next(value);
      }

      items.add(obs);

      if (hooks) {
        var onSubscribe = hooks.onSubscribe;

        if (!TypeGuard.isEmpty(onSubscribe)) {
          onSubscribe.call(hooks, connect);
        }
      }

      return () => {
        items.delete(obs);

        if (hooks) {
          var onUnsubscribe = hooks.onUnsubscribe;

          if (!TypeGuard.isEmpty(onUnsubscribe)) {
            onUnsubscribe.call(hooks, connect);
          }
        }
      };
    });

    _value.set(this, {
      writable: true,
      value: void 0
    });

    _termination.set(this, {
      writable: true,
      value: void 0
    });

    var opts = options || {};
    var items = new Set();
    var values = [];
    var replay = Math.max(0, Number(opts.replay));
    var observable = new Observable(subscriber);

    _classPrivateFieldSet(this, _termination, false);

    var subscription;

    var connect = () => {
      if (subscription && (!subscription.closed || this.closed)) {
        return subscription;
      }

      return observable.subscribe({
        start: subs => subscription = subs,
        next: value => {
          if (this.closed) return;

          _classPrivateFieldSet(this, _value, value);

          if (replay) {
            values.push(value);
            if (values.length > replay) values.shift();
          }

          Invoke.subscriptionObservers('next', value, items);
        },
        error: _error => {
          if (this.closed) {
            var _hooks = HooksManager.get();

            _hooks.onUnhandledError(_error, subscription);
          }

          _classPrivateFieldSet(this, _termination, [_error]);

          Invoke.subscriptionObservers('error', _error, items);
        },
        complete: () => {
          if (this.closed) return;

          _classPrivateFieldSet(this, _termination, true);

          Invoke.subscriptionObservers('complete', undefined, items);
        }
      });
    };

    if (hooks) {
      try {
        var onCreate = hooks.onCreate;

        if (!TypeGuard.isEmpty(onCreate)) {
          onCreate.call(hooks, connect);
        }
      } catch (err) {
        _classPrivateFieldSet(this, _termination, [err]);
      }
    }
  }

  get value() {
    return _classPrivateFieldGet(this, _value);
  }

  get closed() {
    return Boolean(_classPrivateFieldGet(this, _termination));
  }

  [_Symbol$observable]() {
    return Observable.from(this);
  }

}