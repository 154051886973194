export class Handler {
  static noop() {
    return undefined;
  }

  static identity(value) {
    return value;
  }

  static tries(tries, catches, finalizes) {
    try {
      if (tries) tries();
    } catch (err) {
      if (catches) catches(err);
    } finally {
      if (finalizes) finalizes();
    }
  }

  static throws(error) {
    throw error;
  }

}