function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Handler } from "../../helpers/index.js";
import { Hooks } from "../classes/assistance/Hooks.js";
var defaults = {
  onUnhandledError(error) {
    setTimeout(() => Handler.throws(error), 0);
  }

};

var pojo = _objectSpread({}, defaults);

var instance = new Hooks(pojo);
export class HooksManager {
  static set(hooks) {
    pojo = hooks ? _objectSpread(_objectSpread({}, pojo), hooks) : _objectSpread({}, defaults);
    instance = new Hooks(pojo);
  }

  static get() {
    return instance;
  }

}