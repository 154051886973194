var _Symbol$toStringTag;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { until } from "../create/index.js";
var INTERNAL_SYMBOL = Symbol('internal');
_Symbol$toStringTag = Symbol.toStringTag;

/**
 * `Promist` behaves just like a traditional `Promise`, with a few
 * additional features:
 * - It can be externally resolved and/or rejected.
 * - It can also be externally cancelled. If using an executor on
 * the `Promist` constructor, you can receive external completion
 * events  (resolution/rejection/cancellation) via the returned
 * callback, in order to free up resources, if needed. Externally,
 * you also have access to this event (including cancellation)
 * via the `Promist.react` promise.
 * - It will always have the `finally` method available,
 * regardless of the underlying `Promise` implementation.
 *
 * The difference between `Promist`s static methods and create functions
 * is that in any completion event, they will always clean up after themselves,
 * clearing the underlying timeouts and/or subscriptions.
 */
export class Promist {
  /**
   * Creates a `Promist` from a `Promise` or a *sync* or *async* function.
   */
  static from(promise) {
    return new this((resolve, reject) => {
      try {
        Promise.resolve(typeof promise === 'function' ? promise() : promise).then(resolve).catch(reject);
      } catch (err) {
        return reject(err);
      }
    });
  }
  /**
   * Will wait for `ms` milliseconds before resolving with an empty response.
   */


  static wait(ms) {
    return new this(resolve => {
      var timeout = setTimeout(resolve, ms);
      return () => clearTimeout(timeout);
    });
  }
  /**
   * Will not resolve until `test` returns `true`, running it every `ms`
   * milliseconds. If `safe` is `true`, it will treat `test` throws and
   * rejections as `false`, instead of rejecting itself.
   */


  static until(test, safe) {
    var ms = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 25;
    return new this((resolve, reject) => {
      var didComplete = false;
      var timeout = null;
      trunk();

      function reset() {
        if (didComplete) return;
        timeout = setTimeout(trunk, ms);
      }

      function trunk() {
        try {
          Promise.resolve(test()).then(value => value ? resolve() : reset(), reason => safe ? reset() : reject(reason));
        } catch (err) {
          safe ? reset() : reject(err);
        }
      }

      return () => {
        didComplete = true;
        if (timeout) clearTimeout(timeout);
      };
    });
  }
  /**
   * Subscribes to an `observable` and resolves/rejects with
   * its first value. By default, it will reject if the observable
   * completes before emitting any values, though this behavior
   * can be controlled via `onComplete`.
   */


  static subscribe(observable, onComplete) {
    return new this((resolve, reject) => {
      var emitted = false;
      var subscription = observable.subscribe({
        next(value) {
          emitted = true;
          resolve(value);
        },

        error(error) {
          emitted = true;
          reject(error);
        },

        complete() {
          if (emitted) return;

          if (onComplete) {
            onComplete(resolve, reject);
            until(() => Boolean(subscription), true).then(() => subscription.unsubscribe());
          } else {
            reject(Error("Source completed without emitting any values"));
          }
        }

      });
      return () => subscription.unsubscribe();
    });
  }

  constructor(executor) {
    _defineProperty(this, INTERNAL_SYMBOL, void 0);

    var actions = null;
    var promise = new Promise((resolve, reject) => {
      actions = {
        resolve,
        reject
      };
    });
    var internal = {
      promise,
      state: {
        status: 'pending',
        value: null,
        reason: null
      },
      actions
    };
    this[INTERNAL_SYMBOL] = internal;

    if (executor) {
      var complete = executor(this.resolve.bind(this), this.reject.bind(this));

      if (complete && typeof complete === 'function') {
        if (internal.state.status === 'pending') this.react.then(complete);else complete();
      }
    }
  }

  get [_Symbol$toStringTag]() {
    return 'Promise';
  }

  get status() {
    return this[INTERNAL_SYMBOL].state.status;
  }

  get value() {
    return this[INTERNAL_SYMBOL].state.value;
  }

  get reason() {
    return this[INTERNAL_SYMBOL].state.reason;
  }

  get react() {
    var internal = this[INTERNAL_SYMBOL];
    return new Promise(resolve => {
      if (internal.state.status !== 'pending') return resolve();
      var oncomplete = internal.oncomplete;
      internal.oncomplete = oncomplete ? () => {
        oncomplete();
        resolve();
      } : resolve;
    });
  }

  then(onfulfilled, onrejected) {
    return this[INTERNAL_SYMBOL].promise.then(onfulfilled, onrejected);
  }

  catch(onrejected) {
    return this.then(undefined, onrejected);
  }

  finally(fn) {
    return this.then(value => Promise.resolve(fn && fn()).then(() => value), reason => Promise.resolve(fn && fn()).then(() => Promise.reject(reason)));
  }
  /**
   * Resolves the `Promist` with `value`.
   */


  resolve(value) {
    var {
      state,
      actions,
      oncomplete
    } = this[INTERNAL_SYMBOL];
    if (state.status !== 'pending') return;
    state.status = 'resolved';
    state.value = value;
    actions.resolve(value);
    if (oncomplete) oncomplete();
  }
  /**
   * Rejects the `Promist` with `reason`.
   */


  reject(reason) {
    var {
      state,
      actions,
      oncomplete
    } = this[INTERNAL_SYMBOL];
    if (state.status !== 'pending') return;
    state.status = 'rejected';
    state.reason = reason;
    actions.reject(reason);
    if (oncomplete) oncomplete();
  }
  /**
   * Cancels the `Promist`.
   * If it didn't already, it will never resolve nor reject.
   */


  cancel() {
    var {
      state,
      oncomplete
    } = this[INTERNAL_SYMBOL];
    if (state.status !== 'pending') return;
    state.status = 'cancelled';
    if (oncomplete) oncomplete();
  }
  /**
   * Sets a timeout of `ms` milliseconds after which, if the `Promist`
   * hasn't resolved, rejected, or cancelled, it will reject with `reason`.
   */


  timeout(ms, reason) {
    var {
      state
    } = this[INTERNAL_SYMBOL];
    if (state.status !== 'pending') return;
    var timeout = setTimeout(() => {
      this.reject(reason || Error('Promise timed out'));
    }, ms);
    this.react.then(() => clearTimeout(timeout));
  }
  /**
   * Sets a timeout of `ms` milliseconds after which, if the `Promist`
   * hasn't resolved, rejected, or cancelled, it will resolve
   * by falling back to `value`.
   */


  fallback(ms, value) {
    var {
      state
    } = this[INTERNAL_SYMBOL];
    if (state.status !== 'pending') return;
    var timeout = setTimeout(() => this.resolve(value), ms);
    this.react.then(() => clearTimeout(timeout));
  }

}