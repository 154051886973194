var _Symbol$observable;

function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { HooksManager } from "../helpers/index.js";
import { isObservableCompatible, isObservableLike } from "../utils/type-guards.js";
import { Subscription } from "./assistance/index.js";
import { From } from "./helpers/index.js";
import { TypeGuard } from 'type-core';
import 'symbol-observable';

var _subscriber = new WeakMap();

_Symbol$observable = Symbol.observable;
export class Observable {
  static of() {
    var Constructor = TypeGuard.isFunction(this) ? this : Observable;

    for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
      items[_key] = arguments[_key];
    }

    return From.iterable(Constructor, items);
  }

  static from(item) {
    var Constructor = TypeGuard.isFunction(this) ? this : Observable;

    if (item instanceof Observable) {
      return item.constructor === Constructor ? item : From.like(Constructor, item);
    } else if (item.constructor === Constructor) {
      return item;
    }

    if (isObservableCompatible(item)) {
      return From.compatible(Constructor, item);
    }

    if (isObservableLike(item)) {
      return From.like(Constructor, item);
    }

    if (TypeGuard.isIterable(item)) {
      return From.iterable(Constructor, item);
    }

    throw new TypeError("Unable to convert ".concat(typeof item, " into a Observable"));
  }

  constructor(subscriber) {
    _subscriber.set(this, {
      writable: true,
      value: void 0
    });

    if (!TypeGuard.isFunction(subscriber)) {
      throw new TypeError('Expected subscriber to be a function');
    }

    _classPrivateFieldSet(this, _subscriber, subscriber);
  }

  [_Symbol$observable]() {
    return this;
  }

  subscribe(observer) {
    var subscriber = _classPrivateFieldGet(this, _subscriber);

    if (TypeGuard.isFunction(observer)) {
      observer = {
        next: observer,
        error: arguments.length <= 1 ? undefined : arguments[1],
        complete: arguments.length <= 2 ? undefined : arguments[2]
      };
    } else if (!TypeGuard.isObject(observer)) {
      if (!TypeGuard.isEmpty(observer)) {
        subscriber = () => {
          throw new TypeError("Expected observer to be an object or a function");
        };
      }

      observer = {};
    }

    return new Subscription(observer, subscriber, HooksManager.get());
  }

}