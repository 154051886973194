import { operate } from "../../utils/operate.js";
import { intercept } from "../../utils/intercept.js";
export function switchMap(projection) {
  return operate(obs => {
    var index = 0;
    var subscription = null;
    var parentComplete = false;
    return [null, function next(value) {
      if (subscription) subscription.unsubscribe();
      if (obs.closed) return;
      intercept(projection(value, index++), obs, {
        start(subs) {
          subscription = subs;
        },

        complete() {
          if (parentComplete) obs.complete();
        }

      });
    }, null, function complete() {
      parentComplete = true;
      if (subscription && subscription.closed) obs.complete();
    }, function teardown() {
      if (obs.closed || !parentComplete) {
        if (!subscription || subscription.closed) return;
        subscription.unsubscribe();
      }
    }];
  });
}