import { pipe } from "./pipe.js";
export var into = Object.assign(intoFn, {
  async: asyncIntoFn
});

function intoFn(value) {
  for (var _len = arguments.length, fns = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    fns[_key - 1] = arguments[_key];
  }

  return fns.length ? pipe.apply(this, fns)(value) : value;
}

function asyncIntoFn(value) {
  for (var _len2 = arguments.length, fns = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    fns[_key2 - 1] = arguments[_key2];
  }

  return fns.length ? pipe.async.apply(this, fns)(value) : Promise.resolve(value);
}