import { transform } from "../../utils/transform.js";
import { take } from "../filter/take.js";
import { into } from 'pipettes';
/**
 * Returns the first synchronous value of an Observable, if any,
 * `onEmpty` if none is produced, or `onError` if
 * the Observable errors synchronously.
 * When `onError` doesn't exist, `extract` will
 * synchronously throw.
 */

export function extract(onEmpty, onError) {
  return transform(observable => {
    var value;
    var error;
    var subscription = into(observable, take(1)).subscribe({
      next(val) {
        value = [val];
      },

      error(reason) {
        error = [reason];
      }

    });
    subscription.unsubscribe();

    if (error) {
      if (onError) return onError;else throw error[0];
    }

    return value ? value[0] : onEmpty;
  });
}