import { operate } from "../../utils/operate.js";
export function filter(predicate) {
  return operate(obs => {
    var index = 0;
    return {
      next(value) {
        if (predicate(value, index++)) {
          obs.next(value);
        }
      }

    };
  });
}