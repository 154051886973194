function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { TypeGuard } from 'type-core';
export class Exception extends Error {
  /**
   * Tests whether an item is an instance of the `Exception` class
   * or any class inheriting from it.
   * When `item` is an `Exception`, it will optionally
   * test for the instance also having a specific `label`.
   */
  static is(item, label) {
    if (!(item instanceof Exception)) return false;
    if (TypeGuard.isUndefined(label)) return true;
    return item.label === label;
  }
  /**
   * Creates an `Exception` from any exception-like object.
   * When `item` is an `Exception`, a new instance will not be created.
   */


  static from(item) {
    return Exception.is(item) ? item : new Exception([item.label, item.message], item.error, item.data);
  }
  /** An optional label that identifies the exception. */


  /**
   * @param notice a message string or an array containing a label and a message
   * @param error an optional source or original error that caused the exception
   * @param data an optional data field
   */
  constructor(notice, error, data) {
    var [label, message] = TypeGuard.isArray(notice) ? notice : [null, notice];
    super(message);

    _defineProperty(this, "label", void 0);

    _defineProperty(this, "data", void 0);

    _defineProperty(this, "error", void 0);

    this.label = label;
    this.data = data;
    this.error = TypeGuard.isEmpty(error) ? this : error;
  }
  /**
   * `Exception.name` will have value "Exception"
   * or "Exception [label]" instead of "Error"
   */


  get name() {
    return TypeGuard.isEmpty(this.label) ? 'Exception' : "Exception [".concat(this.label, "]");
  }
  /**
   * References the first `Exception` in the `Exception.error` chain.
   */


  root() {
    if (this.error === this) return this;
    if (this.error instanceof Exception) return this.error.root();
    return this;
  }

}