import { shallow, merge, deep } from 'merge-strategies';
import { into } from 'pipettes';
import { assert } from "../assert/index.js";
export function select(value, a, b, c) {
  var {
    selector,
    asserts,
    strategy
  } = {
    selector: c || b || a,
    asserts: (c || b) && typeof a === 'boolean' ? a : false,
    strategy: c && typeof b === 'string' ? b : b && typeof a === 'string' ? a : 'fallback'
  };
  return into(() => {
    switch (typeof value) {
      case 'undefined':
        {
          return 'undefined';
        }

      case 'string':
      case 'number':
      case 'boolean':
        {
          return String(value);
        }

      case 'object':
        {
          if (value === null) return 'null';
        }
      // eslint-disable-next-line no-fallthrough

      default:
        {
          throw Error("Selection value couldn't be stringified: ".concat(value));
        }
    }
  }, fn => {
    var key = fn();

    if (!Object.hasOwnProperty.call(selector, key)) {
      return [undefined, selector.default];
    }

    if (selector.default === undefined || key === 'default') {
      return [undefined, selector[key]];
    }

    return [selector.default, selector[key]];
  }, (_ref) => {
    var [a, b] = _ref;

    switch (strategy) {
      case null:
      case undefined:
      case 'fallback':
        {
          return b;
        }

      case 'shallow':
        {
          return shallow(a, b);
        }

      case 'merge':
        {
          return merge(a, b);
        }

      case 'deep':
        {
          return deep(a, b);
        }

      default:
        {
          throw Error("Invalid select strategy: ".concat(strategy));
        }
    }
  }, value => asserts ? assert(value) : value);
}