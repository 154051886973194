import { operate } from "../../utils/operate.js";
export function debounce(due) {
  return operate(obs => {
    var timeout;
    var push;
    return [null, function next(value) {
      if (timeout) clearTimeout(timeout);

      push = () => {
        push = undefined;
        obs.next(value);
      };

      timeout = setTimeout(() => push ? push() : null, due);
    }, null, function complete() {
      if (timeout) clearTimeout(timeout);
      if (push) push();
      obs.complete();
    }, function teardown() {
      if (timeout) clearTimeout(timeout);
    }];
  });
}