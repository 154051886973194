import { strict, partial, shallow, deep } from "./strategies.js";
export function compare(kind, value, query) {
  switch (kind) {
    case 'strict':
      {
        return strict(value, query);
      }

    case 'partial':
      {
        return partial(value, query);
      }

    case 'shallow':
      {
        return shallow(value, query);
      }

    case 'deep':
      {
        return deep(value, query);
      }

    default:
      {
        throw Error("Invalid equality kind: ".concat(kind));
      }
  }
}