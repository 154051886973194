import { Accessor } from "../../../helpers/index.js";
var $observer = Symbol('observer');
export class SubscriptionManager {
  static setObserver(subscription, observer) {
    Accessor.define(subscription, $observer, observer);
  }

  static getObserver(subscription) {
    return subscription[$observer];
  }

  static close(subscription) {
    Accessor.define(subscription, $observer, null);
  }

  static isClosed(subscription) {
    return this.getObserver(subscription) === null;
  }

}