function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { getPositionalAssertSchema } from "../../helpers/get-positional.js";
import { ensure } from "../ensure/index.js";
import { into } from 'pipettes';
export function coerce(data, a, b) {
  var {
    assert,
    schema
  } = getPositionalAssertSchema(a, b);
  return into(data, data => {
    if (typeof data !== 'object' || data === null) {
      return data;
    }

    if (Array.isArray(data)) {
      if (schema.type === 'array') return data;
      if (schema.type === 'object') return _objectSpread({}, data);
      throw Error("Invalid coercion type for array: ".concat(schema.type));
    } else {
      if (schema.type === 'array') return Object.values(data);
      if (schema.type === 'object') return data;
      throw Error("Invalid coercion type for object: ".concat(schema.type));
    }
  }, data => {
    if (data === undefined) return data;

    switch (schema.type) {
      case 'string':
        {
          var value = String(data);
          return /^".*"$/.test(value) ? value.slice(1, -1) : value;
        }

      case 'integer':
      case 'number':
        {
          var _value = Number(data);

          if (String(_value) === 'NaN') {
            throw Error("Data cannot be coerced to number: ".concat(data));
          }

          return _value;
        }

      case 'null':
      case 'boolean':
        {
          var falsy = ['', '""', '0', 'false', 'null', 'undefined', 'NaN'];
          return typeof data === 'string' ? !falsy.includes(data) : Boolean(data);
        }

      case 'array':
      case 'object':
        {
          if (typeof data === 'object' && data !== null) {
            return data;
          }

          if (typeof data === 'string') {
            var _value2;

            try {
              _value2 = JSON.parse(data);
            } catch (err) {
              throw Error("Invalid JSON data for ".concat(schema.type, ": ").concat(data));
            }

            return _value2;
          }

          throw Error("Data cannot be coerced to ".concat(schema.type, ": ").concat(data));
        }

      default:
        {
          throw Error("Invalid schema type: ".concat(schema.type));
        }
    }
  }, data => {
    if (schema.type === 'null') {
      if (data) throw Error("Data cannot be coerced to null");else data = null;
    }

    return ensure(data, assert, schema);
  });
}