import { operate } from "../../utils/operate.js";
import { TypeGuard } from 'type-core';
/**
 * @param count default: 2
 */

export function trail(count) {
  var number = TypeGuard.isEmpty(count) ? 2 : count;
  return operate(obs => {
    var arr = [];
    return {
      next(value) {
        arr.push(value);
        if (arr.length > number) arr.shift();

        if (arr.length === number) {
          obs.next(Array.from(arr));
        }
      }

    };
  });
}