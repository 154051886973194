import { Observable } from "../classes/Observable.js";
import { intercept } from "../utils/intercept.js";
import { from } from "./from.js";
export function merge() {
  for (var _len = arguments.length, arr = new Array(_len), _key = 0; _key < _len; _key++) {
    arr[_key] = arguments[_key];
  }

  if (arr.length < 1) {
    throw Error("Must provide at least one observable to merge");
  }

  var observables = arr.map(from);
  if (observables.length === 1) return observables[0];
  return new Observable(obs => {
    var completed = 0;
    var subscriptions = observables.map(observable => {
      return obs.closed ? null : intercept(observable, obs, {
        complete() {
          completed++;
          if (completed >= observables.length) obs.complete();
        }

      });
    }).filter(item => Boolean(item));
    return () => {
      for (var subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  });
}