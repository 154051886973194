import { Multicast } from "../../classes/Multicast.js";
import { transform } from "../../utils/transform.js";
import { TypeGuard } from 'type-core';

/**
 * Creates an Observable that multicasts the original Observable.
 * The original Observable won't be subscribed until there is at least
 * one subscriber.
 */
export function share(policy) {
  var options = !policy || TypeGuard.isString(policy) ? {
    policy
  } : policy;
  return transform(observable => {
    var count = 0;
    var observer;
    var subscription;
    return new Multicast(obs => {
      observer = obs;
      return observable.subscribe(obs);
    }, options, {
      onSubscribe(connect) {
        count++;
        subscription = connect();
      },

      onUnsubscribe() {
        count--;
        if (count > 0) return;

        switch (options.policy) {
          case 'keep-open':
            {
              return;
            }

          case 'keep-closed':
            {
              if (observer && !observer.closed) {
                var err = Error('Multicast is already closed');
                observer.error(err);
              }

              if (subscription) subscription.unsubscribe();
              return;
            }

          default:
            {
              if (subscription) subscription.unsubscribe();
              subscription = null;
            }
        }
      }

    });
  });
}