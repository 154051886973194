import { operate } from "../../utils/operate.js";
import { TypeGuard } from 'type-core';
export function take(count) {
  var options = !count || TypeGuard.isNumber(count) ? {
    count
  } : count;
  return operate(obs => {
    var index = -1;
    var stop = false;
    return {
      next(value) {
        if (stop) return;
        index++;

        if (options.count && index < options.count) {
          return obs.next(value);
        }

        if (options.while && options.while(value, index)) {
          return obs.next(value);
        }

        stop = true;
        return obs.complete();
      }

    };
  });
}