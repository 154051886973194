import { TypeGuard } from 'type-core';
import 'symbol-observable';
export function isObservableConvertible(item) {
  return isObservableLike(item) || isObservableCompatible(item) || TypeGuard.isIterable(item);
}
export function isObservableLike(item) {
  return TypeGuard.isObject(item) && TypeGuard.isFunction(item.subscribe);
}
export function isObservableCompatible(item) {
  return TypeGuard.isObject(item) && TypeGuard.isFunction(item[Symbol.observable]);
}
export function isObservable(item) {
  return TypeGuard.isObject(item) && TypeGuard.isFunction(item[Symbol.observable]) && TypeGuard.isFunction(item.subscribe);
}
export function isSubscriptionLike(item) {
  return TypeGuard.isObject(item) && TypeGuard.isFunction(item.unsubscribe);
}
export function isSubscription(item) {
  return TypeGuard.isObject(item) && TypeGuard.isBoolean(item.closed) && TypeGuard.isFunction(item.unsubscribe);
}