import { operate } from "../../utils/operate.js";
import { TypeGuard } from 'type-core';
import { compare as isEqual } from 'equal-strategies';
/** @ignore */

var $empty = Symbol('empty');
export function compare(strategy) {
  var fn = !strategy || TypeGuard.isString(strategy) ? isEqual.bind(null, strategy || 'strict') : strategy;
  return operate(obs => {
    var last = $empty;
    return {
      next(value) {
        if (last === $empty || !fn(value, last)) {
          last = value;
          return obs.next(value);
        }
      }

    };
  });
}