import { operate } from "../../utils/operate.js";
import { TypeGuard } from 'type-core';
export function skip(count) {
  var options = !count || TypeGuard.isNumber(count) ? {
    count
  } : count;
  return operate(obs => {
    var index = -1;
    var stop = false;
    return {
      next(value) {
        index++;
        if (stop) return obs.next(value);
        if (options.count && index < options.count) return;
        if (options.while && options.while(value, index)) return;
        stop = true;
        return obs.next(value);
      }

    };
  });
}