function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { MachineSubject } from "./Machine.js";
import { SourceEnclosure } from "./Source.js";

var _machine = new WeakMap();

/**
 * The most basic Resource abstract class.
 * The `source`, `active`, `source$`, and `active$` properties,
 * as well as the `next`, `enable`, and `disable` methods,
 * are set as `protected` instead of `public`.
 * For cases when these are only to be used internally.
 */
export class ResourceEnclosure extends SourceEnclosure {
  constructor(state, deps, enable, projection) {
    super(state, deps, projection);

    _machine.set(this, {
      writable: true,
      value: void 0
    });

    _classPrivateFieldSet(this, _machine, new MachineSubject(enable));
  }

  get active() {
    return _classPrivateFieldGet(this, _machine).active;
  }

  get active$() {
    return _classPrivateFieldGet(this, _machine).active$;
  }

  enable() {
    return _classPrivateFieldGet(this, _machine).enable();
  }

  disable() {
    return _classPrivateFieldGet(this, _machine).disable();
  }

}
/**
 * A `Resource` implementation as an abstract class.
 */

export class SuperResource extends ResourceEnclosure {
  get state() {
    return super.state;
  }

  get active() {
    return super.active;
  }

  get state$() {
    return super.state$;
  }

  get active$() {
    return super.active$;
  }

  enable() {
    return super.enable();
  }

  disable() {
    return super.disable();
  }

}
/**
 * A `Resource` implementation as a concrete class.
 */

export class ResourceSubject extends SuperResource {
  constructor(state, enable, projection) {
    super(state, null, enable, projection);
  }

  next(state) {
    return super.next(state);
  }

}