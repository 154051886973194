function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { Invoke } from "../helpers/index.js";

var _hooks = new WeakMap();

export class Hooks {
  static from(hooks) {
    return hooks instanceof this ? hooks : new this(hooks);
  }

  constructor(hooks) {
    _hooks.set(this, {
      writable: true,
      value: void 0
    });

    _classPrivateFieldSet(this, _hooks, hooks);
  }

  onUnhandledError(error, subscription) {
    return Invoke.method(_classPrivateFieldGet(this, _hooks), 'onUnhandledError', [error, subscription]);
  }

  onStoppedNotification(value, subscription) {
    try {
      Invoke.method(_classPrivateFieldGet(this, _hooks), 'onStoppedNotification', [value, subscription]);
    } catch (err) {
      this.onUnhandledError(err, subscription);
    }
  }

}