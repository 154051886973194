function _classPrivateFieldGet(receiver, privateMap) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to get private field on non-instance"); } if (descriptor.get) { return descriptor.get.call(receiver); } return descriptor.value; }

function _classPrivateFieldSet(receiver, privateMap, value) { var descriptor = privateMap.get(receiver); if (!descriptor) { throw new TypeError("attempted to set private field on non-instance"); } if (descriptor.set) { descriptor.set.call(receiver, value); } else { if (!descriptor.writable) { throw new TypeError("attempted to set read only private field"); } descriptor.value = value; } return value; }

import { Handler } from "../../../helpers/index.js";
import { teardown } from "../../utils/teardown.js";
import { Invoke, SubscriptionManager } from "../helpers/index.js";
import { SubscriptionObserver } from "./SubscriptionObserver.js";
import { Hooks } from "./Hooks.js";

var _teardown = new WeakMap();

var _hooks = new WeakMap();

class Subscription {
  constructor(observer, subscriber) {
    _teardown.set(this, {
      writable: true,
      value: void 0
    });

    _hooks.set(this, {
      writable: true,
      value: void 0
    });

    _classPrivateFieldSet(this, _teardown, null);

    _classPrivateFieldSet(this, _hooks, Hooks.from(arguments.length <= 2 ? undefined : arguments[2]));

    SubscriptionManager.setObserver(this, observer);
    Invoke.observer('start', this, this, _classPrivateFieldGet(this, _hooks));
    if (SubscriptionManager.isClosed(this)) return;
    var subscriptionObserver = new SubscriptionObserver(this, arguments.length <= 2 ? undefined : arguments[2]);
    var fn = Handler.noop;

    try {
      var unsubscribe = subscriber(subscriptionObserver);
      fn = teardown(unsubscribe);
    } catch (err) {
      subscriptionObserver.error(err);
    } finally {
      if (SubscriptionManager.isClosed(this)) {
        try {
          fn();
        } catch (err) {
          _classPrivateFieldGet(this, _hooks).onUnhandledError(err, this);
        }
      } else {
        _classPrivateFieldSet(this, _teardown, fn);
      }
    }
  }

  get closed() {
    return SubscriptionManager.isClosed(this);
  }

  unsubscribe() {
    if (!SubscriptionManager.isClosed(this)) {
      SubscriptionManager.close(this);
    }

    var teardown = _classPrivateFieldGet(this, _teardown);

    if (!teardown) return;

    _classPrivateFieldSet(this, _teardown, null);

    try {
      teardown();
    } catch (err) {
      _classPrivateFieldGet(this, _hooks).onUnhandledError(err, this);
    }
  }

}

Subscription.prototype.constructor = Object;
export { Subscription };