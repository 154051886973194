/**
 * Will not resolve until `test` returns `true`, running it every `ms`
 * milliseconds. If `safe` is `true`, it will treat `test` throws and
 * rejections as `false`, instead of rejecting itself.
 */
export function until(test, safe) {
  var ms = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 25;
  return new Promise((resolve, reject) => {
    var reset = () => setTimeout(trunk, ms);

    trunk();

    function trunk() {
      try {
        Promise.resolve(test()).then(value => value ? resolve() : reset(), reason => safe ? reset() : reject(reason));
      } catch (err) {
        safe ? reset() : reject(err);
      }
    }
  });
}