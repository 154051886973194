import { Observable } from "../classes/Observable.js";
export function fromPromise(promise) {
  return new Observable(obs => {
    promise.then(value => {
      obs.next(value);
      obs.complete();
    }, error => {
      obs.error(error);
    });
  });
}