function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

import { TypeGuard } from 'type-core';
import { Result } from "./Result.js";
export class Consume {
  /**
   * Consumes a *result.* When successful, it will return its `data` field,
   * and otherwise throw an *Error.*
   */
  static result(result) {
    if (!result) return result;

    if (result.success) {
      return result.data;
    } else {
      var error = result.data;
      if (error instanceof Error) throw error;
      var str;

      try {
        str = TypeGuard.isObject(error) ? JSON.stringify(error) : String(error);
      } catch (_) {
        str = String(error);
      }

      throw Error("Result failed with ".concat(typeof error, ": ") + str);
    }
  }
  /**
   * Consumes a *Promise* of a *result.* If successful,
   * it will return a *Promise* resolving with its `data` field,
   * and otherwise reject with an *Error.*
   */


  static promise(promise) {
    return _asyncToGenerator(function* () {
      return Consume.result(yield Promise.resolve(promise));
    })();
  }
  /**
   * Consumes an *Observable* of *result.*
   * Returns an *Observable* that will emit values of
   * the *result* `data` field as long as they're successful,
   * and otherwise error with an *Error*.
   * @param Constructor an ES Observable constructor
   * @param observable an ES Observable of *result*
   */


  static observable(Constructor, observable) {
    return new Constructor(obs => {
      var unsubscribe = false;
      var subs;
      var subscription = observable.subscribe({
        start(subscription) {
          subs = subscription;
        },

        next(result) {
          /* istanbul ignore next */
          if (unsubscribe) return;
          var value;
          var error;

          try {
            value = Consume.result(result);
          } catch (err) {
            error = [err];
          }

          if (!error) {
            obs.next(value);
          } else {
            obs.error(error[0]);
            /* istanbul ignore next */

            if (typeof subs !== 'undefined') {
              subs.unsubscribe();
            } else if (typeof subscription !== 'undefined') {
              subscription.unsubscribe();
            } else {
              unsubscribe = true;
            }
          }
        },

        error(reason) {
          obs.error(reason);
        },

        complete() {
          obs.complete();
        }

      });
      /* istanbul ignore next */

      if (unsubscribe) subscription.unsubscribe();
      return () => subscription.unsubscribe();
    });
  }

}