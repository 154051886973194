import { Observable } from "../../classes/Observable.js";
import { transform } from "../../utils/transform.js";
import { intercept } from "../../utils/intercept.js";
import { from } from "../../creators/from.js";
export function catches(selector) {
  return transform(observable => {
    return new Observable(obs => {
      var subscriptions = [];
      intercept(observable, obs, {
        start(subscription) {
          subscriptions.push(subscription);
        },

        error(reason) {
          from(selector(reason, observable)).subscribe({
            start(subscription) {
              subscriptions.push(subscription);
            },

            next: obs.next.bind(obs),
            error: obs.error.bind(obs),
            complete: obs.complete.bind(obs)
          });
        }

      });
      return () => {
        for (var subscription of subscriptions) {
          subscription.unsubscribe();
        }
      };
    });
  });
}