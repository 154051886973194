import { from } from "../creators/from.js";
import { Multicast } from "../classes/Multicast.js";
import { TypeGuard } from 'type-core';
export function useObservable(React, observable, projection) {
  var state = React.useState(0);
  var store = React.useMemo(() => {
    var members = {
      subscription: null,
      result: null,
      response: null
    };
    var i = 0;
    var open = false;
    var updated = false;

    function update(value) {
      updated = true;
      var previous = members.response;
      members.result = value;
      members.response = projection ? projection(value) : value;
      if (open && members.response !== previous) state[1](i = i + 1);
    }

    from(TypeGuard.isFunction(observable) ? observable() : observable).subscribe({
      start(subscription) {
        members.subscription = subscription;
      },

      next(item) {
        update({
          success: true,
          done: false,
          data: item
        });
      },

      error(reason) {
        update({
          success: false,
          done: true,
          data: reason
        });
      },

      complete() {
        update(members.result && members.result.success ? {
          success: true,
          done: true,
          data: members.result.data
        } : {
          success: false,
          done: true,
          data: Error("Observable completed before pushing any value")
        });
      }

    });
    open = true;
    if (!updated && projection) members.response = projection(null);
    return members;
  }, []);
  React.useEffect(() => () => {
    if (store.subscription) store.subscription.unsubscribe();
  }, []);
  return store.response;
}
export function usePropsMulticast(React, props, projection) {
  var store = React.useMemo(() => {
    var observer;
    var multicast = new Multicast(obs => {
      observer = obs;
    }, {
      replay: true
    }, {
      onCreate: connect => connect()
    });
    observer.next(projection ? projection(props) : props);
    return {
      props,
      observer,
      multicast
    };
  }, []);

  if (props !== store.props) {
    store.props = props;
    var value = projection ? projection(props) : props;
    if (value !== store.multicast.value) store.observer.next(value);
  }

  return store.multicast;
}