function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Ajv from 'ajv';
import deep from 'lodash.clonedeep';
import { getPositionalAssertSchema } from "../../helpers/get-positional.js";
export function ensure(data, a, b) {
  var ajv = new Ajv({
    useDefaults: true
  });
  var {
    assert,
    schema
  } = getPositionalAssertSchema(a, b);

  if (!ajv.validateSchema(schema)) {
    /* istanbul ignore next */
    throw ajv.errors ? Error("Schema is not valid: " + ajv.errorsText(ajv.errors)) : Error("Schema is not valid");
  }

  var item = {
    data: deep(data)
  };
  var valid = ajv.validate({
    type: 'object',
    required: assert ? ['data'] : [],
    properties: {
      data: schema
    }
  }, item);
  if (valid) return item.data;
  /* istanbul ignore next */

  if (!ajv.errors) throw Error("Data is not valid");
  var message = ajv.errorsText(ajv.errors.map(error => {
    return _objectSpread({}, error, {
      dataPath: error.dataPath.replace(/^\.data/, ''),
      schemaPath: error.schemaPath.replace(/^#\/properties\/data/, '/#'),
      message: (error.message || 'is not valid').replace(/should have required property '\.?data'/, 'should not be undefined')
    });
  }));
  throw Error("Data is not valid: " + message);
}