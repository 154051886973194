function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { shallow } from 'merge-strategies';
import { PureCollection } from "./PureCollection.js";
var internal = Symbol('Collection');
export class Collection extends PureCollection {
  constructor(variables, initial) {
    super(() => initial(new PureCollection(() => this[internal].variables)));

    _defineProperty(this, internal, void 0);

    this[internal] = {
      variables,
      initial
    };
  }

  create(variables) {
    var instance = new Collection(this[internal].variables, this[internal].initial);
    return variables ? instance.use(variables) : instance;
  }

  use(variables) {
    if (variables) {
      this[internal].variables = shallow(this[internal].variables, variables);
    }

    return this.clear();
  }

}